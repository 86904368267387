import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryBrickyard = ({ data }) => {
  return (
    <SuccessStory
      title="Scalable Image Anonymization for Law Enforcement"
      customerName="Brickyard"
      customerLocation="Amsterdam, Netherlands"
      customerDescription="Brickyard (part of Waysis) develops and delivers innovative mobility solutions, mainly in the field of law enforcement using camera enforcement, parking enforcement, and scanning cars. Ultimately, they are experts in combining data and bringing it together smartly via user-friendly cloud-based software to help cities and municipalities increase livability in cities."
      customerIndustries="Law enforcement"
      link="https://www.brickyard.eu/"
      logo=""
      style={{
        background: "url(/images/celantur-brickyard-face-lp.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          Brickyard's cutting-edge solutions are based on the collection of RGB and IR images using their camera
          enforcement and scanning car systems.
        </strong>
      </p>
      <p>
        Camera enforcement solutions encompass fixed camera units mounted on poles, monitoring authorized vehicle entry
        into designated areas. Scan cars are based on modified conventional (electric) vehicles, equipped with advanced
        features like ANPR camera systems, precise GPS units, and panoramic cameras.
      </p>
      <p>
        With the increasingly stringent Dutch and European GDPR regulations, Brickyard faced the challenge of ensuring
        the privacy and compliance of individuals captured in the imagery data - e.g., faces and license plates of
        vehicles that are not in violation.
      </p>
      <p>
        Notably, the challenge was to exclude the license plates of vehicles involved in code violations from being
        blurred to facilitate effective identification and enforcement. Also, being a cloud-based solution, the
        anonymization process had to be deployed securely within Brickyard's own cloud infrastructure to prevent data
        transfers outside their databases.
      </p>
      <h2 className="my-3">Solution</h2>
      <p>
        Brickyard turned to Celantur for our expertise in automated image anonymization. Leveraging Celantur's
        Container, the solution was integrated into Brickyard's cloud environment, providing the security and
        scalability required to process approximately 30,000 images per day.
      </p>
      <p>
        A remarkable aspect of the solution was the need to exclude specific license plates from being blurred. To
        address this, Celantur and Brickyard collaborated to develop a custom feature. The feature utilized coordinates
        from the input JSON file to automatically select areas in the image that needed to stay unblurred. This process
        enabled Brickyard's cloud-based system to accurately identify and enforce traffic violations while respecting
        privacy.
      </p>

      <figure className="figure">
        <Img fluid={data.brickyard1.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Blurred imagery captured by Brickyard. ©
Brickyard BV</figcaption>
      </figure>

      <h2 className="my-3">Summary</h2>
      <JobFeature>
        Celantur Container effectively anonymizes approximately 30,000 images daily, ensuring privacy and compliance.
      </JobFeature>
      <JobFeature>
        A custom feature was developed to exclude specific license plates from blurring, meeting Brickyard's unique
        requirements.
      </JobFeature>
      <JobFeature>
        Celantur Container seamlessly integrated into Brickyard's cloud environment, providing a scalable and secure
        infrastructure.
      </JobFeature>

    </SuccessStory>
  )
}

export default SuccessStoryBrickyard

export const query = graphql`
  query {
    brickyard1: file(relativePath: { eq: "celantur-brickyard-face-lp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
